import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

class Terms extends React.Component {
  render() {
    return (
      <Page
        className={classNames('legal', 'terms', this.props.className)}
        headerTitle="Terms of Service"
      >
        <PageSection>
          <h4>Looking for our Broadcaster Terms of Service?{' '}
            <a href="https://live365.com/legal/terms-broadcaster/" target="_blank" rel="noopener noreferrer">
              Click Here
            </a>
            .
          </h4>
        </PageSection>
        <PageSection>
          <p>
            These Live365.com Terms of Use (this "Agreement") is a legal
            agreement between you and Live365 Broadcaster, LLC ("Live365", "we",
            "us" or "our") providing, among other things, the terms and
            conditions for your use of Live365's basic services, associated
            mobile applications, software and Internet services under our
            control, whether partial or otherwise, in connection with providing
            a platform for Live365's services (collectively, the "Live365
            Products and Services"), including our Web site https://live365.com
            (the "Site").
          </p>

          <p>
            We may from time to time modify these Terms of Use and will post a
            copy of the amended Agreement on this page. If you do not agree to,
            or cannot comply with, the Agreement as amended, you must stop using
            Live365’s Products and Services. You will be deemed to have accepted
            the Agreement, as it may be amended from time to time, if you
            continue to use any of the Live365 Products and Services after any
            amendments to such Agreement are posted on the Site.
          </p>
        </PageSection>

        <PageSection>
          <h3>Services</h3>

          <p>
            The “Service” means, collectively, (a) Live365’s audio service
            offering access to a selection of radio stations, music mixes and
            other content whether available through our Site or through any
            software applications made available for mobile devices
            (“Applications”) and (b) all software, external players, buttons and
            widgets, data, text, images, sounds, video, graphics and photos made
            available by Live365 through any of the foregoing (collectively, the
            “Content”). Access to this Content is via digital transmissions to
            an end user over the Internet where the Content may be heard or
            viewed, but not downloaded for later playback. You may not attempt,
            or support others&#39; attempts, to download, copy, distribute,
            alter or capture this Content.
          </p>
        </PageSection>

        <PageSection>
          <h3>Trademarks</h3>

          <p>
            The following are licensed trademarks or trademarks of Live365:
            Live365, and its registered design logo, as well as certain other
            Live365 trademarks, service marks, graphics, and logos
            (collectively, the "Live365 Trademarks") used in connection with the
            Live365 Products and Services. The Live365 Products and Services may
            contain third party trademarks, service marks, graphics, and logos.
            You are not granted any right or license with respect to Live365
            Trademarks or the trademarks of any third party.
          </p>
        </PageSection>

        <PageSection>
          <h3>Privacy policy</h3>

          <p>
            Your use of the Service is subject to the Privacy Policy, which is
            hereby incorporated by reference into these Terms of Use and posted
            at{' '}
            <Link to="/legal/privacy">https://live365.com/legal/privacy</Link>.
            By using the Service you agree that you have read, understood, and
            agree to the data collection, use, and disclosure provisions set
            forth in the Privacy Policy.
          </p>
        </PageSection>

        <PageSection>
          <h3>Parental advisory</h3>

          <p>
            If you are a user between the ages of 13 and 17, please review these
            terms of use with your parents. The Live365 Products and Services
            are intended for users who are at least 13 years of age or older.
            Because the Live365 Products and Services may provide access to
            music that contains explicit content including strong language or
            depictions of violence, sex or substance abuse, parental discretion
            is advised for all users ages 13 to 17. This agreement contains
            warranty and liability disclaimers. By using or subscribing to the
            Live365 Products and Services, you accept and agree to the terms and
            conditions of this agreement. In order for you to have access to the
            Live365 Products and Services, this agreement must be accepted by
            you without any modifications, additions, or deletions. If you do
            not agree to the terms of use contained in this agreement, you are
            not authorized to use the Live365 Products and Services.
          </p>
        </PageSection>

        <PageSection>
          <h3>Third parties</h3>

          <p>
            Live365 or its business partners may present advertisements or
            promotional materials on or through the Site and the Live365
            Products and Services. Your dealings with, or participation in
            promotions of, any third party advertisers on or through the Site
            are solely between you and such third party and your participation
            is subject to the terms and conditions associated with that
            advertisement or promotion. You agree that Live365 is not
            responsible or liable for any loss or damage of any sort incurred as
            the result of any such dealings or as the result of the presence of
            such third parties on the Site.
          </p>

          <p>
            The Service may also include links to third party websites or third
            party services that are not owned or operated by Live365
            (collectively, “Third Party Sites”). We do not endorse or represent
            such third parties and we are not responsible for the availability
            of these Third Party Sites, or any products, services and/or content
            contained therein. You agree that we are not responsible or liable,
            directly or indirectly, for any loss or damage of any sort incurred
            as a result of your use of or reliance on any Third Party Sites.
          </p>

          <p>
            You may be able to link and/or post content to or from third party
            websites (e.g., Facebook) and the Site. You are solely responsible
            for any such content you post, and for any information contained
            therein. You are also solely responsible for complying with any
            applicable law, regulation and or rule of any applicable government
            agency and/or such third party website.
          </p>

          <p>
            You acknowledge that Live365 neither endorses the contents of your
            communications or the communications of third parties on or relating
            to the Live365 Products and Services, nor assumes the responsibility
            for any threatening, libelous, obscene, harassing or offensive
            material contained therein, any infringement of third party
            intellectual property rights arising therefrom or any crime
            facilitated thereby.
          </p>
        </PageSection>

        <PageSection>
          <h3>Digital Millennium Copyright Act ("DMCA")</h3>

          <p>
            In operating the Live365 Products and Services, we may act as a
            "services provider" (as defined by DMCA) and offer services as
            online provider of materials and links to third party websites. As a
            result, third party materials that we do not own or control may be
            transmitted, stored, accessed or otherwise made available using the
            Live365 Products and Services. Live365 has in place certain legally
            mandated procedures regarding allegations of copyright infringement
            which can be found at{' '}
            <Link to="/legal/dmca">https://live365.com/legal/dmca</Link>.
          </p>
        </PageSection>

        <PageSection>
          <h3>Disclaimers</h3>

          <p>
            Your use of this website is at your risk. The information, materials
            and services provided on or through this website are provided "as
            is" without any warranties of any kind including warranties of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property. Neither Live365, nor any
            of its affiliates warrant the accuracy or completeness of the
            information, materials or services provided on or through this
            website. The information, materials and services provided on or
            through this website may be out of date, and neither Live365, nor
            any of its affiliates makes any commitment or assumes any duty to
            update such information, materials or services. The foregoing
            exclusions of implied warranties do not apply to the extent
            prohibited by law. Please refer to your local laws for any such
            prohibitions. No advice or information, whether oral or written,
            obtained from Live365 or through the Site, Live365 Products and
            Services will create any warranty not expressly made herein.
          </p>
        </PageSection>

        <PageSection>
          <h3>Limitation of liability</h3>

          <p>
            Live365 does not assume any responsibility, or will be liable, for
            any damages to, or any viruses that may infect your computer,
            telecommunication equipment, or other property caused by or arising
            from your access to, use of, or browsing this Site. All Content is
            provided to you on an "as is" "as available" basis without warranty
            of any kind either express or implied, including but not limited to
            the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement. Live365 makes no warranty as to the
            accuracy, completeness, currency, or reliability of any Content
            available through the Live365’s Product and Services. Use of the
            Live365 Products and Services is at your sole risk. Live365 makes no
            representations or warranties that use of the Live365 Products and
            Services will be uninterrupted or error-free. IN NO EVENT WILL
            LIVE365, OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS,
            AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, NOR ANY PARTY INVOLVED IN
            THE CREATION, PRODUCTION OR TRANSMISSION OF THIS WEBSITE, BE LIABLE
            TO YOU OR ANYONE ELSE FOR ANY INDIRECT, SPECIAL, PUNITIVE,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION,
            THOSE RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS
            INTERRUPTION) ARISING OUT OF THE USE, INABILITY TO USE, OR THE
            RESULTS OF USE OF THIS WEBSITE, ANY WEBSITES LINKED TO THIS WEBSITE,
            OR THE MATERIALS, INFORMATION OR SERVICES CONTAINED ON ANY OR ALL
            SUCH WEBSITES, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY
            OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO
            THE EXTENT PROHIBITED BY LAW. YOU AGREE THAT YOUR SOLE REMEDY IS TO
            CEASE USING THIS WEBSITE.
          </p>
        </PageSection>

        <PageSection>
          <h3>Indemnity</h3>

          <p>
            You agree to defend, indemnify and hold Live365 and any affiliated
            company or individual harmless from any and all liabilities, costs,
            and expenses, including reasonable attorneys&#39; fees, related to
            or in connection with (i) the use of the Live365 Products and
            Services (ii) your violation of any term of this Agreement,
            including without limitation, your breach of any of the
            representations and warranties above (iii) your violation of any
            third party right, including without limitation any right of
            privacy, publicity rights or Intellectual Property Rights or (iv)
            your violation of any law, rule or regulation of the United States
            or any other country. You shall not sue or seek to recover any
            damages or other payment from Live365 and its agents,
            representatives, employees, Licensors, affiliates, content and
            technology providers, and any parent or subsidiary company as a
            result of its decision to terminate or suspend the Terms of Use and
            service hereunder.
          </p>
        </PageSection>

        <PageSection>
          <h3>Disputes</h3>

          <p>
            Any dispute relating in any way to your visit to the Site or to
            Products or Services you use through Live365 shall be submitted to
            confidential arbitration in Pennsylvania, except that, to the extent
            you have in any manner violated or threatened to violate Live365’s
            intellectual property rights, Live365 may seek injunctive or other
            appropriate relief in any state or federal court in the state of
            Pennsylvania, and you consent to exclusive jurisdiction and venue in
            such courts. Arbitration under this agreement shall be conducted
            under the rules then prevailing of the American Arbitration
            Association. The arbitrator’s award shall be binding and may be
            entered as a judgment in any court of competent jurisdiction. To the
            fullest extent permitted by applicable law, no arbitration under
            this Agreement shall be joined to an arbitration involving any other
            party subject to this Agreement, whether through class arbitration
            proceedings or otherwise.
          </p>
        </PageSection>

        <PageSection>
          <h3>Our Address</h3>
          <address>
            <span className="address-line">Live365</span>
            <span className="address-line">1340 Monticello Avenue</span>
            <span className="address-line">Hermitage, PA 16148</span>
            <span className="address-line">privacy@live365.com</span>
          </address>
        </PageSection>
      </Page>
    )
  }
}

export default Terms
