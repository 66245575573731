import React from 'react'

import SEO from '../../components/SEO'
import Footer from '../../components/Footer'

import PageContent from '../../old/pages/legal/Terms'
import '../../assets/styles.css'
import '../../old/pages/legal.css'

export default () => (
  <React.Fragment>
    <SEO title={'Terms of Service'} />
    <PageContent />
    <Footer />
  </React.Fragment>
)
